import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import careHelpfulFunctions from '../careHelpfulFunctions.jsx';
import utils from '../../Shared/utils.jsx';
import EventBus from '../event-bus.js';
import methods from '../../Shared/methods';
import { debounce } from "lodash";

import { AgGridVue } from "ag-grid-vue";

import AgContentDef from './AgContentDef.jsx';

var baseMixin = {
    mixins: [BaseComponent],
    components: {
        AgGridVue,
        AgContentDef,
    },
    data: () => ({
        unique_id: '',
        dataGridRefName: null,
        gridcomponent: null,
        menu_is_open: false,

        schemaurl: null,
        schemaraw: null,
        rowClassExpr: null,
        modelurl: null,
        modelvalue: null,
        schema: null,
        modelfunc: null,
        storageFunc: null,
        showFilter: false,
        headerControls: {},
        addrowdata: [],
        addrowdata_todo: 0,
        addrowdata_count: 0,
        addrowdata_inprogress: false,
        addrowdata_promises: [],

        selectedRowKeys: [],
        selected_rows: {},
        selectedRowsData: [],
        columnDefs: null,

        displayMode: 'full',
        pageSizes: [10, 15, 20, 25, 30, 35, 40, 45, 50],
        showPageSizeSelector: true,
        showInfo: true,
        showNavButtons: true,
        scrollMode: "virtual",

        loading: false,
        selectableexpn: null,

        pagination: false,
        columns_visible: [], // Array of boolean to show/hide a column
        allowExportAll: true,
        // Don't allow these to be reactive, messes up reactivity for some reason
        // gridApi: null,
        // columnApi: null,

        searchText: '',
        rowDataTemp: null,
        jumpToValue: ''
    }),
    props: {
    },
    created() {
        this.unique_id = utils.generateUUID();
        this.dataGridRefName = `dataGrid_${utils.generateUUID()}`;

        if (this.controlData.DisableLocalStorage != undefined && this.controlData.DisableLocalStorage != null) {
            this.storageFunc = utils.compile(this, this.controlData.DisableLocalStorage);
        }

        if (this.controlData.SchemaURL)
        {
            this.schemaurl = utils.compile(this, this.controlData.SchemaURL);

            this.schemaurl_watch$ = this.$watch(
                function () {
                    try {
                        return utils.evaluate(this.schemaurl, this);
                    }
                    catch (e)
                    {
                        utils.warn('BasicGrid schemaurl ' + this.controlData.SchemaURL + ' failed to evaluate: ' + e);
                        return '';
                    }
                },
                function (val, oldval) {
                    this.Refresh(true);
                }
            );
        }
              

        if ((this.controlData.DataType === 'URL' || this.controlData.DataType === undefined) && this.controlData.DataURL)
        {
            this.modelurl = utils.compile(this, this.controlData.DataURL);
        }
        else if (this.controlData.DataType === 'Raw' && this.controlData.Data)
        {
            this.modelvalue = utils.compileObject(this, this.controlData.Data);
        }

        // Special case here to handle interpolated column definitions (used in Advanced History reports)
        if (typeof this.controlData.ColumnDefs === 'string' && (this.controlData.ColumnDefs.includes('{#') || this.controlData.ColumnDefs.includes('{%'))) {
            const columnDefEval = utils.compileObject(this, this.controlData.ColumnDefs);
            this.columnDefs = utils.evaluateObject(columnDefEval, this);

            if (this.controlData.ColumnDefs.includes('{%')) {
                const self = this;
                this.columnDefs_watch$ = this.$watch(
                    function () { return utils.evaluateObject(columnDefEval, self); },
                    function (newv) {
                        this.columnDefs = newv;
                    }
                );
            }
        }
        else
            this.columnDefs = this.controlData.ColumnDefs || [];

        if (this.columnDefs) {
            for (let i = 0; i < this.columnDefs.length; i++) {
                const cdef = this.columnDefs[i];
                if (cdef.ContentDefs) {
                    for (let j = 0; j < cdef.ContentDefs.length; j++) {
                        const contentdef = cdef.ContentDefs[j];
                        if (contentdef.DisplayExpression) {
                            contentdef.displayExpression = utils.compileExpression(this, contentdef.DisplayExpression);
                        }
                    }
                }
                else if (cdef.Field) {
                    cdef.column_eval = new Function('model', 'return model.' + cdef.Field + ' || "";');
                }
            }
        }

        // Read this.columns_visible from local storage if present
        const cv = this.System.LocalStorage(this.storageKey + '-columns_visible');
        
        if (cv) {
            const cvObj = JSON.parse(cv);

            if (Array.isArray(cvObj)) {
                Vue.set(this, 'columns_visible', cvObj);
            }
        }

        if (Array.isArray(this.columnDefs)) {
            if (!cv || this.columns_visible.length !== this.columnDefs.length) {
                this.columns_visible = this.columnDefs.map(d => {
                    if (!('Visible' in d)) {
                        return true;
                    }

                    if (typeof d.Visible === "string" && d.Visible.length > 0) {
                        let evalExpression = utils.compile(this, d.Visible);
                        return utils.evaluateObject(evalExpression, this) === true;
                    }
                    
                    return d.Visible === true;
                });
            }
        }


        if (this.controlData.SelectOptions && this.controlData.SelectOptions.Enable && this.controlData.SelectOptions.SelectableExpression)
            this.selectableexpn = utils.compileExpression(this, this.controlData.SelectOptions.SelectableExpression, 'row');        
        // This is a temporary non-reactive copy of the visibility flags for use when generating the columns.
        // We don't want the columns to react to this object changing since we'll be calling into the columnApi
        // directly to toggle a column's visibility (much faster).
        this.init_columns_visible = [...this.columns_visible];

        // Read pagination settings if present
        const pg = this.System.LocalStorage(this.storageKey + '-standard_pagination');
        if (typeof pg === 'string')
            this.pagination = pg == 'true';

        // Subscribe to a UIAction_GridRefresh event
        this.$on('Action-GridRefresh', this.performGridRefresh);
        this.$on('Action-GridClearSelectedRows', this.performGridClearSelectedRows);

        EventBus.$on('Action-GridRefresh', this.performGridRefresh);
        EventBus.$on('Action-GridClearSelectedRows', this.performGridClearSelectedRows);

        // Subscribe by name so Target can be directed to a specific container
        if (this.controlData.Name) {
            this.$on(`Action-GridRefresh:${this.controlData.Name}`, this.performGridRefresh);
            EventBus.$on(`Action-GridRefresh:${this.controlData.Name}`, this.performGridRefresh);

            this.$on(`Action-GridClearSelectedRows:${this.controlData.Name}`, this.performGridClearSelectedRows);
            EventBus.$on(`Action-GridClearSelectedRows:${this.controlData.Name}`, this.performGridClearSelectedRows);
        }

        // AdditionalRowData contains expressions and async lookups that are evaluated once for the whole
        // dataset at load time. As the data materializes, rows that reference it will get updated.
        if (this.controlData.AdditionalRowData)
            for (let i = 0; i < this.controlData.AdditionalRowData.length; i++) {
                const add = this.controlData.AdditionalRowData[i];
                this.addrowdata.push({
                    name: add.Name,
                    initValue: add.InitialValue ? utils.compileObject(this, add.InitialValue) : null,
                    contentDefs: add.ContentDefs.map(c => ({
                        displayexpr: c.DisplayExpression ? utils.compileExpression(this, c.DisplayExpression) : null,
                        type: c.DataType,
                        valueexpr: c.Value ? utils.compileObject(this, c.Value) : null,
                        urlexpr: (c.URL && c.DataType == 'AsyncLookup') ? utils.compile(this, c.URL) : null,
                        cache: c.CacheReply !== undefined ? c.CacheReply : true,
                    }))
                });
            }
    },
    destroyed() {
        utils.log('Full BasicGrid destroyed()');
        if (this.schemaurl_watch$) this.schemaurl_watch$(); // disposes of the watcher
        if (this.schemaraw_watch$) this.schemaraw_watch$();
        if (this.modelurl_watch$) this.modelurl_watch$();
        if (this.modelvalue_watch$) this.modelvalue_watch$();
        if (this.columnDefs_watch$) this.columnDefs_watch$();

        this.$off('Action-GridRefresh', this.performGridRefresh);
        this.$off('Action-GridClearSelectedRows', this.performGridClearSelectedRows);

        EventBus.$off('Action-GridRefresh', this.performGridRefresh);
        EventBus.$off('Action-GridClearSelectedRows', this.performGridClearSelectedRows);

        if (this.controlData.Name) {
            this.$off(`Action-GridRefresh:${this.controlData.Name}`, this.performGridRefresh);
            EventBus.$off(`Action-GridRefresh:${this.controlData.Name}`, this.performGridRefresh);

            this.$off(`Action-GridClearSelectedRows:${this.controlData.Name}`, this.performGridClearSelectedRows);
            EventBus.$off(`Action-GridClearSelectedRows:${this.controlData.Name}`, this.performGridClearSelectedRows);
        }
        if (this.watch_date_value$)
            this.watch_date_value$();
        if (this.rowclass_watch$)
            this.rowclass_watch$();
    },
    computed: {
        dataGrid: function () {
            const r = this.$refs[this.dataGridRefName];
            if (r)
                return r.instance;
            else
                return undefined;
        },
        Model: function () {
            if (this.modelfunc)
                return this.modelfunc();
            else
                return null;
        },
        useLocalStorage: function () {
            if (this.storageFunc) {
                return !utils.evaluate(this.storageFunc, this);
            }

            return true;
        },
        storageKey: function () {
            return `basicGrid-${this.parentType}-${this.controlData.Name || 'unnamed'}-${this.controlData.$objectid || 'noid'}`;
        },
        schemaurlvalue: function () {
            if (this.schemaurl)
                return utils.evaluate(this.schemaurl, this);
            else
                return '';
        },
        modelurlvalue: function () {
            if (this.modelurl)
                return utils.evaluate(this.modelurl, this);
            else
                return '';
        },
        modelrawvalue: function () {
            if (this.modelvalue)
                return utils.evaluateObject(this.modelvalue, this);
            else
                return [];
        },

        Rows: function () {
            return []; // this.modelfunc();
        },
        SelectedRows: function () {
            return this.selected_rows;
        },
        SelectedRowsAsArray: function () {
            return this.selectedRowsData;
        },
        HasSelectedRows: function () {
            return false;
        },
        GridColumns: function () {
            return this.columnDefs;
        },

        gridHeaders: function () {
            const h = this.$createElement;
            const items = [];

            if (this.controlData.EnableFilter) {
                items.push(
                    <v-text-field
                        style="max-width: 300px;"
                        class="mb-1"
                        dense hide-details
                        clear-icon="mdi-close-circle"
                        clearable
                        placeholder="Quick Search"
                        prepend-inner-icon="mdi-magnify"
                        value={this.searchText}
                        on-input={(v) => this.searchText = v}
                    ></v-text-field>
                );
            }
            else if (this.controlData.ShowJumpto) {
                items.push(<v-text-field
                    style="max-width: 300px;"
                    class="mb-1"
                    dense hide-details
                    clear-icon="mdi-close-circle"
                    clearable
                    placeholder="Jump to..."
                    prepend-inner-icon="mdi-magnify"
                    value={this.jumpToValue}
                    on-input={(v) => {
                        this.jumpToValue = v;
                        this.performGridReset(true);
                    }}
                    ></v-text-field>
                );
            }

            this.getLeftGridHeaderControls(items);

            // Separator to push remaining items to right
            items.push(<span style="flex-grow: 1;"></span>);

            if (this.addrowdata_todo)
                items.push(
                    <v-progress-linear
                        style="max-width: 100px;"
                        xvalue={this.addrowdata_todo}
                        value={100 * ((this.addrowdata_count - this.addrowdata_todo) / this.addrowdata_count)}>
                    </v-progress-linear>
                );

            if (this.selectedRowsData.length > 0)
                items.push(
                    <span>( {this.selectedRowsData.length} Selected
                        <v-btn elevation={0} icon on-click={(e) => this.ClearSelectedRows()}>
                            <v-icon >mdi-close-circle</v-icon>
                        </v-btn>)
                    </span>
                );

            this.getGridHeaderControls(items);

            if (!this.controlData.HideDefaultGridControls) {
                // Resizer (runs auto-resize for all columns)
                items.push(utils.generateTooltip(h,
                    <v-btn elevation={0}  plain on-click={(e) => this.gridApi.autoSizeAllColumns()}>
                        <v-icon>
                            mdi-arrow-left-right-bold
                        </v-icon>
                    </v-btn>, 'Size columns to content', 'bottom'));

                // Resizer fit to screen (runs resize for all columns to fit all within screen width)
                items.push(utils.generateTooltip(h,
                    <v-btn elevation={0}  plain on-click={(e) => this.gridApi.sizeColumnsToFit()}>
                        <v-icon>
                            mdi-arrow-expand-horizontal
                        </v-icon>
                    </v-btn>, 'Size columns to fit', 'bottom'));

                if (!this.controlData.HeaderOptions?.HideRefreshIcon) {
                    // Refresh
                    items.push(utils.generateTooltip(h,
                        <v-btn elevation={0}  plain on-click={(e) => this.RefreshClicked()}>
                            <v-icon>
                                mdi-cached
                            </v-icon>
                        </v-btn>, 'Refresh', 'bottom'));
                }
            }

            if (!this.controlData.DisableGridMenu) {
                const scopedSlots = {
                    activator: ({ on, attrs }) =>
                        <v-btn elevation={0}  plain
                            {...{ on }}
                            {...{ attrs }}
                        >
                            <v-icon>
                                mdi-menu
                            </v-icon>
                        </v-btn>
                };

                const menus = [];

                if(this.allowExportAll)
                    menus.push(
                        <v-list-item key={0} on-click={(e) => { this.menu_is_open = false; this.ExportData(true); }}>
                            <v-list-item-icon>
                                <v-icon >mdi-table-arrow-right</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Export all data as csv</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    );

                menus.push(
                    <v-list-item key={1} on-click={(e) => { this.menu_is_open = false; this.ExportData(false); }}>
                        <v-list-item-icon>
                            <v-icon >mdi-table-arrow-right</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Export visible data as csv</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                );

                menus.push(
                    <v-list-item key={2} on-click={(e) => this.RestoreDefaults(e)}>
                        <v-list-item-icon>
                            <v-icon >mdi-file-undo</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Restore default settings</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                );

                menus.push(<v-divider></v-divider>);

                menus.push(
                    <v-list-item key={3} on-click={() => { this.menu_is_open = false; this.togglePagination(); }}>
                        <v-list-item-icon>
                            <v-icon >{this.pagination ? 'mdi-check' : ''}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Use standard pagination</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                );

                menus.push(<v-divider></v-divider>);

                // Add all columns with check boxes for visible

                this.getGridMenuColumnList(h, menus);

                // Menu
                const menu =
                    <v-menu
                        offset-y
                        close-on-click={true}
                        close-on-content-click={false}
                        scopedSlots={scopedSlots}
                        value={this.menu_is_open}
                        on-input={(state) => this.menu_is_open = state}
                    >
                        <v-list dense style="max-height: 600px" class="overflow-y-auto">
                            <v-list-item-group>
                                {menus}
                            </v-list-item-group>
                        </v-list>
                    </v-menu>;

                items.push(menu);
            }

            return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {items}
                </div>
            );
        },
        agColumnDefs: function() {
            let coldefs;
            if (this.columnDefs && this.columnDefs.length > 0) {
                coldefs = [];

                let checkboxVisible = true;
                if (this.selectableexpn)
                    checkboxVisible = ({ data }) => utils.evaluate(this.selectableexpn, this, false, null, false, data);

                if (this.controlData.SelectOptions.Enable)
                    coldefs.push({
                        headerName: '',
                        sortable: false,
                        resizable: true,
                        checkboxSelection: checkboxVisible,
                        headerCheckboxSelection: true,
                        headerCheckboxSelectionFilteredOnly: true,
                        showDisabledCheckboxes: this.controlData.SelectOptions.ShowDisabledCheckboxes == true,
                        pinned: 'left',
                        width: 50,
                    });

                for (let i = 0; i < this.columnDefs.length; i++) {
                    const cd = this.columnDefs[i];
                    let sortComparitor = this.colSortComparator; // this.numberSortComparator;

                    if (!cd.visibleexpr && cd.Visible && typeof cd.Visible === 'string') {
                        cd.visibleexpr = utils.compileObject(this, cd.Visible);
                    }

                    if (cd.CellClassExpression && !cd.cellclassexpr)
                        cd.cellclassexpr = utils.compileExpression(this, cd.CellClassExpression);

                    let cssClass;
                    if (cd.cellclassexpr)
                        cssClass = ({ node }) => { this.rowDataTemp = node.data; return utils.evaluate(cd.cellclassexpr, this); }

                    let calcCellValue;

                    if (this.controlData.SearchOnlyVisibleColumns) {
                        cd.getQuickFilterText = params => {
                            if (params.colDef.hide || !params.value)
                                return '';

                            return params.value?.toString();
                        };
                    }

                    if (cd.SortAndFilterTemplate) {
                        if (!cd.sortandfilterfunc) {
                            if (false) // (cd.SortAndFilterTemplate.includes('{#') || cd.SortAndFilterTemplate.includes('{%'))
                                cd.sortandfilterfunc = utils.compileObject(this, cd.SortAndFilterTemplate);
                            else
                            cd.sortandfilterfunc = utils.compile(this, cd.SortAndFilterTemplate);
                        }

                        if (cd.SortAndFilterTemplate.includes('{#') || cd.SortAndFilterTemplate.includes('{%'))
                            sortComparitor = this.numberSortComparator;

                        if (false) { // (cd.SortAndFilterTemplate.includes('{#') || cd.SortAndFilterTemplate.includes('{%')) {
                            calcCellValue = (node) => { this.rowDataTemp = node.data; return utils.evaluateObject(cd.sortandfilterfunc, this); };
                        }
                        else {
                            calcCellValue = (node) => { this.rowDataTemp = node.data; return utils.evaluate(cd.sortandfilterfunc, this); };
                        }
                    }

                    let cdef;

                    if (cd.ContentDefs && cd.ContentDefs.length > 0) {
                        // This code is used when the grid cell has a ContentDefs array defined, which
                        // will insert custom controls into the grid cell using this template named here:
                        // (the template gets its definition above and generates a content-def component)

                        let dataType;
                        if (cd.CellFilter) {
                            const parts = cd.CellFilter.split(':');
                            if (parts[0] == 'number')
                                dataType = 'number';
                            else if (parts[0].includes('date'))
                                dataType = 'date';
                            else if (parts[0].includes('time'))
                                dataType = 'datetime';
                        }

                        cdef = {
                            headerName: cd.ActionOnlyColumn ? '' : (cd.DisplayName || cd.Name || cd.Field),
                            field: cd.ActionOnlyColumn ? '' : (cd.Field || cd.Name),
                            colId: cd.Name || cd.Field,
                            sortable: cd.Sortable && !cd.ActionOnlyColumn,
                            filter: true && !cd.ActionOnlyColumn,
                            resizable: true,
                            comparator: sortComparitor,
                            hide: !this.init_columns_visible[i],
                            getQuickFilterText: cd.getQuickFilterText || undefined,
                            valueGetter: calcCellValue,
                            autoHeight: cd.AutoHeight,
                            cellRenderer: 'AgContentDef',
                            cellRendererParams: {
                                callcorp: {
                                    root: this.root,
                                    contentDefs: cd.ContentDefs,
                                    addrowdata: this.addrowdata,
                                    scopeitems: this.scopeitems,
                                    controlscope: this.controlscope,
                                    field: cd.Field || cd.Name,
                                    context: this,
                                    loading: i == 0 // mark column as the loading column
                                }
                            },
                        };
                    }
                    else {
                        // This code is used when the grid cell does not have a ContentDefs array, so 
                        // the contents are automatically generated as the value of the record
                        let dataType;
                        if (cd.CellFilter) {
                            const parts = cd.CellFilter.split(':');
                            if (parts[0] == 'number')
                                dataType = 'number';
                            else if (parts[0].includes('date'))
                                dataType = 'date';
                            else if (parts[0].includes('time'))
                                dataType = 'datetime';
                        }

                        if (cd.CellFilter && !cd.cellFilterFunc) {
                            // Take ex: date:'medium'
                            // Generate function as:
                            // return context.f_date(data.field, 'medium')  -- context === this
                            const parts = cd.CellFilter.split(':');
                            const code = `return context.f_${parts[0]}(data.${cd.Field},${parts[1]});`;
                            const func = new Function('context', 'data', code);
                            cd.cellFilterFunc = (node) => {
                                if (node.data) {
                                    try { return func(this, node.data); } catch (e) {
                                        utils.warn(`Grid cell failed to evaluate ${code}`, e); return '';
                                    }
                                } else {
                                    return '';
                                }
                            };
                        }
                        else if (!cd.CellFilter && !cd.CellFilterFunc) {
                            let nullchecks = '';
                            if (cd.Field && cd.Field.includes('.')) {
                                const parts = cd.Field.split('.');
                                for (let i = 0; i < parts.length - 1; i++)
                                    nullchecks += (nullchecks ? ' && ' : '') + `data.${parts[i]}`;
                                nullchecks += ` ? (!!value!!) : ''`;
                            }
                            let code = `typeof data.${cd.Field} === 'object' ? JSON.stringify(data.${cd.Field}) : data.${cd.Field}`;

                            if (nullchecks)
                                code = nullchecks.replace('!!value!!', code);

                            code = 'return ' + code + ';';
                            const func = new Function('data', code);
                            cd.cellFilterFunc = (node) => {
                                if (node.data) {
                                    try { return func(node.data); } catch (e) {
                                        utils.warn(`Grid cell failed to evaluate ${code}`, e); return '';
                                    }
                                } else {
                                    return '';
                                }
                            };
                        }

                        if (this.type == 'BasicGrid' && !calcCellValue) {
                            // This affects the sorting when using a BasicGrid, but the InfiniteGrid lets the server
                            // do the sorting, so this isn't necessary and it causes problems in the logic that tells
                            // the server what column we are sorting on. So we have to skip this for the InfiniteGrid.
                            const code = `return typeof data.${cd.Field} === 'object' ? JSON.stringify(data.${cd.Field}) : data.${cd.Field};`;
                            const func = new Function('data', code);
                            calcCellValue = (node) => {
                                try { return func(node.data); } catch (e) {
                                    utils.warn(`Grid cell failed to evaluate ${code}`, e); return '';
                                }
                            };
                        }

                        cdef = {
                            headerName: cd.DisplayName || cd.Name || cd.Field,
                            field: cd.Field || cd.Name,
                            colId: cd.Name || cd.Field,
                            sortable: cd.Sortable,
                            filter: true,
                            resizable: true,
                            comparator: sortComparitor,
                            autoHeight: cd.AutoHeight,
                            hide: !this.init_columns_visible[i],
                            getQuickFilterText: cd.getQuickFilterText || undefined
                        };
                        if (calcCellValue)
                            cdef.valueGetter = calcCellValue;

                        if (cd.cellFilterFunc)
                            cdef.valueFormatter = cd.cellFilterFunc;
                    }

                    if (cssClass)
                        cdef.cellClass = cssClass;

                    switch (cd.Align) {
                        case 'Right': cdef.type = 'rightAligned'; break;
                        case 'Center': cdef.cellStyle = { textAlign: 'center' }; break; // center doesn't work, not sure why
                    }

                    if (this.type == 'InfiniteGrid')
                        cdef.suppressHeaderMenuButton = true;

                    coldefs.push(cdef);
                }
            }
            return coldefs;
        },

        totalItems() {
            let count = this.controlData.GridHeaderControls ? this.controlData.GridHeaderControls.length : 0;

            if (this.controlData.LeftGridHeaderControls)
                count += this.controlData.LeftGridHeaderControls.length;

            return count + 1;
        },
    },
    methods: {
        preRenderComplete() {
            //this.finishRenderHandler(this);
        },

        async postRenderComplete() {
            await this.LoadSchema();
            await this.Refresh(false);
        },
        async performGridReset(clearSelectedRows) {
            if (clearSelectedRows)
                this.ClearSelectedRows();

            if (this.gridApi)
                this.gridApi.onSortChanged();
        },

        async performGridRefresh(action) {
            utils.log(`${this.type} ${this.name} GridRefresh`);

            if (action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                await this.Refresh(action.ActionData.ClearSelectedRows);
                
                if (this.controlData.RefreshActions)
                    await utils.executeAndCompileAllActions(this.controlData.RefreshActions, null, this);

                try {
                    await utils.success(action);
                } catch (e) { }
            }
            catch (e) {
                try {
                    await utils.failure(action);
                } catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
        isRowSelectable(rowNode) {
            if (this.controlData.SelectOptions.Enable) {
                if (this.selectableexpn) {
                    // dynamic decision
                    return utils.evaluate(this.selectableexpn, this, false, null, false, rowNode.data);
                } else {
                    // no expression but we are enabled.
                    return true;
                }
            } else {
                // we are not enabled.
                return false;
            }
        },
        async performGridClearSelectedRows(action) {
            utils.log(`${this.type} ${this.name} GridClearSelectedRows`);

            if (action.ActionData.Debug && action.ActionData.Debug.BreakPoint) debugger;

            try {
                this.ClearSelectedRows();

                try {
                    await utils.success(action);
                } catch (e) { }
            }
            catch (e) {
                try {
                    await utils.failure(action);
                } catch (e) { }
            }
            finally {
                try {
                    await utils.complete(action);
                }
                catch (e) { }

                // Complete the promise for the executeAction method
                action.FinishFunc(true);
            }
        },
        async LoadSchema() {
            if (this.schemaurl)
                try {
                    this.schema = await utils.schema.get(this.schemaurlvalue);
                }
                catch (e) {
                    utils.warn('BasicGrid schemaurl ' + this.controlData.SchemaURL + ' failed to retrieve: ' + e);
                    this.schema = null;
                }
        },
        async internalRefresh(clearSelectedRows) {
            if (clearSelectedRows)
                this.ClearSelectedRows();

            //this.modelfunc = null;

            let model;
            if (this.controlData.DataType == 'URL' && this.modelurl) {
                try {
                    model = this.modelurlvalue ? await utils.api.get(this.modelurlvalue, false, false) : [];
                }
                catch (e) {

                    // SignalR backwards compatability
                    var statusCode = e.StatusCode ? e.StatusCode : e.statusCode;
                    var reasonPhrase = e.ReasonPhrase ? e.ReasonPhrase : e.reasonPhrase;

                    let msg = `Failed in API call to ${this.modelurlvalue}`;
                    utils.warn(`${this.type} ${this.name} ${msg}`, e);
                    msg = `API call failed: ${reasonPhrase}`;
                    EventBus.$emit('v-toast', {
                        text: 'Error',
                        subtext: e ? msg : 'Unknown error',
                        type: 'error',
                        icon: 'mdi mdi-alert',
                        ontap: [
                            {
                                "PrimitiveAction": true,
                                "ActionType": "DisplayConfirmModal",
                                "RunAsync": false,
                                "ActionData": {
                                    "OkayButtonText": "Okay",
                                    "CancelButtonText": "Cancel",
                                    "Debug": {},
                                    "Title": "Error " + (e ? statusCode : ''),
                                    "Message": `Failed in API call to ${this.modelurlvalue} - ${reasonPhrase}`
                                }
                            }
                        ],
                        context: this,
                    });
                }

                if (!this.modelurl_watch$)
                    this.modelurl_watch$ = this.$watch(
                        function () {
                            return this.modelurlvalue;
                        },
                        function (val, oldval) {
                            utils.debug(`BasicGridMixin ${this.name} URL value changed to: ${val}`);
                            this.Refresh();
                        }
                    );
            }
            else if (this.controlData.DataType == 'Raw' && this.modelvalue)
                try {
                    model = this.modelrawvalue;
                    
                    if (!this.modelvalue_watch$)
                        this.modelvalue_watch$ = this.$watch(
                            function () {
                                try {
                                    return this.modelrawvalue;
                                }
                                catch (e) {
                                    utils.warn('Full BasicGrid modelvalue ' + this.controlData.Model.Definition + ' failed to evaluate: ' + e);
                                    return null;
                                }
                            },
                            function (val, oldval) {
                                this.Refresh();
                            },
                            {
                                deep: false
                            }
                        );
                }
                catch (e) {
                    utils.warn('BasicGrid modelvalue ' + this.controlData.Data + ' failed to evaluate: ' + e);
                    model = [];
                }
            else
                model = [];

            this.modelfunc = () => model;

            if (this.gridApi)
                this.gridApi.refreshCells();

            setTimeout(() => this.resolveAllAdditionalRowData(), 1000);
        },

        RefreshClicked() {
            if (this.controlData.DataType == 'URL')
                this.Refresh();
            else if (this.controlData.HeaderOptions?.RefreshIconActions
                && this.controlData.HeaderOptions?.RefreshIconActions.length > 0)
                    utils.executeAndCompileAllActions(this.controlData.HeaderOptions.RefreshIconActions, null, this);
            else
                utils.executeAndCompileAllActions([
                    {
                        "PrimitiveAction": true,
                        "ActionType": "Refresh",
                        "RunAsync": false,
                        "ActionData": {
                            "Debug": {
                                "BreakPoint": false
                            }
                        },
                    }
                ], {}, this);

        },
        RefreshCells() {
            this.gridApi.refreshCells();
        },
        ClearSelectedRows() {
            utils.debug(`${this.type} ${this.name} ClearSelectedRows()`);

            this.selected_rows = {};
            this.selectedRowsData = [];
            this.gridApi?.deselectAll();
        },
        SetSelectedRows(selectedArray, shouldClearSelection) {
            utils.debug(`BasicGrid ${this.name}.SetSelectedRows(${JSON.stringify(selectedArray)})`);

            // selectedArray is an array containing objects with the KeyFields - ex: KeyFields:['ID','Name']
            // then selectedArray might be [{'ID':'1', 'Name':'James'}, {'ID':'2', 'Name': 'Sam'}]

            // Therefore, to update the grid properly, we have to locate each row by key and update the selected_rows and selectedRowsData:
            //const all_rows = this.modelvalue();
            //if (!all_rows) return;

            const to_select_rows = [];
            const to_select_keys = {};

            //for (let i = 0; i < selectedArray.length; i++) {
            //    const key = selectedArray[i];
            //    const row = all_rows.find(a => Object.keys(key).every(k => a[k] == key[k]));
            //    if (row) {
            //        to_select_rows.push(row);
            //        const str_key = Object.keys(key).map(k => `_${row[k]}`).join('');
            //        to_select_keys[str_key] = row;
            //    }
            //}
            const keys = this.controlData.KeyFields;
            const to_find = [...selectedArray];

            this.gridApi.forEachNode(node => {
                // Set if this should be selected
                const idx = to_find.findIndex(key => Object.keys(key).every(k => node.data[k] == key[k]));
                const isSelected = idx >= 0; // selectedArray.some(key => Object.keys(key).every(k => node.data[k] == key[k]));
                node.setSelected(isSelected);
                if (isSelected) {
                    // Remove the found item from the to_find array to reduce how many items we have to skip over
                    to_find.splice(idx, 1);
                    // Save the row data in the selected rows array
                    to_select_rows.push(node.data);
                    // Build the row key and save in the selected keys object
                    const str_key = keys.map(k => `_${row[k]}`).join('');
                    to_select_keys[str_key] = node.data;
                }
            });

            if (shouldClearSelection) {
                this.selected_rows = to_select_keys;
                this.selectedRowsData = to_select_rows;
            }
            else {
                Object.keys(to_select_keys).forEach(key => Vue.set(this.selected_rows, key, to_select_keys[key]));
                to_select_rows.forEach(item => this.selectedRowsData.push(item));
            }
        },
        SizeColumnsToContent() {
            this.gridApi.autoSizeAllColumns();
        },
        NotifyDataChange() {

        },
        async ExportData(all) {
            await this.resolveAllAdditionalRowData();
            this.gridApi.exportDataAsCsv({ allColumns: all, processCellCallback: this.processCellCallback });
        },
        processCellCallback(params){
            try{
                if(this.allowExportAll){
                    return params.value;
                }
                else if(params.column.colDef?.cellRendererParams?.callcorp && params.column.colDef.field && params.node.data.exportValues && params.node.data.exportValues[params.column.colDef.field]){
                    return params.node.data.exportValues[params.column.colDef.field]?.join(' ') || "";
                }
                else return "";
            }catch(err){
                debugger;
            }
        },

        columnFilterChanged(e) {
            if (!this.gridApi) return;
            
            var filterModel = this.gridApi.getFilterModel();

            if (this.useLocalStorage) {
                this.System.LocalStorage(this.storageKey + '-column_filters', JSON.stringify(filterModel));
            }
        },

        colSortComparator(valueA, valueB) {
            // Enable case insensitive sort
            if (typeof valueA === 'string' && typeof valueB === 'string')
                return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
            else if (typeof valueA === 'integer' && typeof valueB === 'integer')
                return valueA - valueB;
            else if (typeof valueA === 'number' && typeof valueB === 'number')
                return valueA - valueB;
            else
                return 0;
        },
        numberSortComparator(num1, num2) {
            // Enable nmerical sort
            return num1 - num2;
        },
        toggleScrollMode(e) {
            if (this.scrollMode == 'virtual')
                this.scrollMode = 'standard';
            else
                this.scrollMode = 'virtual';
        },
        toggleColumnVisible(field, index) {
            // This directly alters the visibility
            this.gridApi.setColumnsVisible([field], !this.columns_visible[index]);

            // The checkboxes in the grid menu are bound to this
            Vue.set(this.columns_visible, index, !this.columns_visible[index]);

            if (this.useLocalStorage) {
                this.System.LocalStorage(this.storageKey + '-columns_visible', JSON.stringify(this.columns_visible));
            }
        },
        setColumnVisible(field, index, value, override) {
            // This directly alters the visibility
            
            if (this.gridApi) {
                this.gridApi.setColumnsVisible([field], value);
                if(override)
                    Vue.set(this.init_columns_visible, index, value);
            }
            else {
                Vue.set(this.init_columns_visible, index, value);
                Vue.set(this.columns_visible, index, value);
            }
            
        },
        togglePagination() {
            this.pagination = !this.pagination;

            if (this.useLocalStorage) {
                this.System.LocalStorage(this.storageKey + '-standard_pagination', this.pagination ? 'true' : 'false');
            }

            this.unique_id = utils.generateUUID();
        },

        getRowClass(params) {
            if (this.controlData.RowClassExpression)
            {
                this.rowClassExpr = utils.compileExpression(this, this.controlData.RowClassExpression);

                //set row data and index for interpolation to work.
                this.rowDataTemp = params.data;
                this.rowIndex = params.rowIndex;

                try {
                    return utils.evaluate(this.rowClassExpr, this);
                }
                catch (e)
                {
                    utils.warn('Grid RowClassExpression ' + this.controlData.RowClassExpression + ' failed to evaluate: ' + e);
                    return '';
                }
            }
        },

        getGridMenuColumnList(h, menus) {
            for (let i = 0; i < this.columnDefs.length; i++) {
                const col = this.columnDefs[i];
                if (col.ActionOnlyColumn) continue;

                menus.push(
                    <v-list-item key={i + 4} on-click={() => this.toggleColumnVisible(col.Name || col.Field, i)}>
                        <v-list-item-icon>
                            <v-icon >{this.columns_visible[i] ? 'mdi-check' : ''}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{col.DisplayName || col.Name || col.Field}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                );
            }
        },
        //unused
        getGridMenuContent(h, data) {
            const items = this.getGridMenuColumnList(h);

            return (
                <v-menu
                    offset-y
                    left
                    close-on-content-click={false}
                    scopedSlots={{ activator: ({ on, attrs }) => <v-btn elevation={0}  text {...{ on }} {...{ attrs }}><v-icon>menu</v-icon></v-btn> }}
                >
                    <v-list dense style="max-height: 600px" class="overflow-y-auto">
                        <v-list-item-group color="primary">
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Export all data as csv</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Export visible data as csv</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Restore default settings</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                        </v-list-item-group>

                        <v-divider></v-divider>

                        <v-subheader>Grid Options</v-subheader>
                        <v-list-item-group color="primary">

                            <v-list-item on-click={(e) => this.toggleScrollMode(e)}>
                                <v-list-item-icon>
                                    <v-icon >{this.scrollMode == "standard" ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline'}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Standard Paging</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item on-click={(e) => this.toggleFilter(e)}>
                                <v-list-item-icon>
                                    <v-icon >{this.showFilter ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline'}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Show Column Filters</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                        </v-list-item-group>

                        <v-divider></v-divider>

                        <v-subheader>Columns Visible</v-subheader>
                        <v-list-item-group color="primary">
                            {items}
                        </v-list-item-group>
                    </v-list>
                </v-menu>
            );
        },

        refreshDataGrid() {
            this.Refresh(false);
        },

        async resolveAllAdditionalRowData() {
            if (!this.addrowdata || !this.addrowdata.length)
                return;

            if (this.addrowdata_inprogress) {
                let resolver;
                const promise = new Promise(function (resolve, reject) { resolver = resolve; });
                this.addrowdata_promises.push(resolver);
                await promise;
                return;
            }

            try {
                this.addrowdata_inprogress = true;
                this.addrowdata_promises = [];

                const model = this.modelfunc();
                const todo = [];
                for (let i = 0; i < model.length; i++) {
                    const row = model[i];
                    if (!('$$additional_row_data' in row))
                        todo.push(row);
                }

                if (todo.length < 1)
                    return;

                this.addrowdata_count = todo.length;
                this.addrowdata_todo = todo.length;

                const promises = [];
                for (let i = 0; i < todo.length; i++) {
                    promises.push(this.resolveAdditionalRowData(i, todo[i]));
                }
                await Promise.all(promises);
            }
            finally {
                this.addrowdata_inprogress = false;

                if (this.addrowdata_promises.length)
                    this.addrowdata_promises.forEach(r => r());
            }
        },
        async resolveAdditionalRowData(rowIndex, row) {
            try {
                this.rowDataTemp = row;

                if (this.addrowdata && !('$$additional_row_data' in row)) {
                    Vue.set(row, '$$additional_row_data', {});

                    for (let j = 0; j < this.addrowdata.length; j++) {
                        const add = this.addrowdata[j];
                        if (add.initValue) {
                            Vue.set(row.$$additional_row_data, add.name, utils.evaluateObject(add.initValue, this));
                        }

                        for (let i = 0; i < add.contentDefs.length; i++) {
                            const c = add.contentDefs[i];
                            if (c.displayexpr && !utils.evaluate(c.displayexpr, this))
                                continue;

                            // Set on the component so the interpolation can read RowIndex() and RowData() as expected
                            this.rowIndex = rowIndex;
                            this.rowDataTemp = row;

                            switch (c.type) {
                                case 'RawInterpolated':
                                    Vue.set(row.$$additional_row_data, add.name, utils.evaluateObject(c.valueexpr, this));
                                    break;

                                case 'AsyncLookup':
                                    Vue.set(row.$$additional_row_data, add.name, {});
                                    const url = utils.evaluate(c.urlexpr, this);
                                    const res = await utils.api.get(url, false, c.cache)

                                    // Set on component so the interpolation can read Input as expected
                                    this.Input = { Data: res };
                                    Vue.set(row.$$additional_row_data, add.name, utils.evaluateObject(c.valueexpr, this));

                                    break;
                            }
                        }
                    }
                }
            }
            catch (e) {
                utils.warn(`${this.type} ${this.name} AdditionalRowData[${rowIndex}] error`, e);
            }
            finally {
                this.addrowdata_todo--;
            }
        },
        getAdditionalRowData(name) {
            if (this.rowDataTemp && ('$$additional_row_data' in this.rowDataTemp))
                return name ? this.rowDataTemp.$$additional_row_data[name] : this.rowDataTemp.$$additional_row_data;
            else
                return {};
        },

        getGridHeaderControls(items) {
            if (this.controlData.GridHeaderControls && Array.isArray(this.controlData.GridHeaderControls) && this.controlData.GridHeaderControls.length > 0) {
                for (let i = 0; i < this.controlData.GridHeaderControls.length; i++) {
                    const c = this.controlData.GridHeaderControls[i];

                    let DynamicControl = utils.getDynamicComponent(h, c);

                    if (!DynamicControl)
                        DynamicControl = 'default-unknown';

                    items.push(
                        <DynamicControl
                            on={{ 'finished-render': (reference) => this.finishRenderHandler(reference) }}
                            type={c.ControlType}
                            name={c.ControlData.Name ? c.ControlData.Name : ''}
                            root={this.root}
                            parentType="HorizontalStack"
                            controlData={c.ControlData}
                            controlURL={c.ControlURL}
                            controlName={c.Name}
                            scopeitems={this.scopeitems}
                            controlscope={this.controlscope}
                            cacheControl={c.CacheControl}
                            controlEvents={c.Events}>
                        </DynamicControl>);
                }
            }
        },
        getLeftGridHeaderControls(items) {
            if (this.controlData.LeftGridHeaderControls && Array.isArray(this.controlData.LeftGridHeaderControls) && this.controlData.LeftGridHeaderControls.length > 0) {
                for (let i = 0; i < this.controlData.LeftGridHeaderControls.length; i++) {
                    const c = this.controlData.LeftGridHeaderControls[i];

                    let DynamicControl = utils.getDynamicComponent(h, c);

                    if (!DynamicControl)
                        DynamicControl = 'default-unknown';

                    items.push(
                        <DynamicControl
                            on={{ 'finished-render': (reference) => this.finishRenderHandler(reference) }}
                            type={c.ControlType}
                            name={c.ControlData.Name ? c.ControlData.Name : ''}
                            root={this.root}
                            parentType="HorizontalStack"
                            controlData={c.ControlData}
                            controlURL={c.ControlURL}
                            controlName={c.Name}
                            scopeitems={this.scopeitems}
                            controlscope={this.controlscope}
                            controlEvents={c.Events}
                        >
                        </DynamicControl>);
                }
            }
            // if (!this.controlData.DisableGridMenu)
        },

        RestoreDefaults() {
            this.menu_is_open = false;

            // Clear all persistent settings
            this.System.LocalStorage(this.storageKey + '-state', null);
            this.System.LocalStorage(this.storageKey + '-columns_visible', null);
            this.System.LocalStorage(this.storageKey + '-standard_pagination', null);
            this.System.LocalStorage(this.storageKey + '-column_filters', null);
            
            if (Array.isArray(this.columnDefs))
                this.columns_visible = this.columnDefs.map(d => !('Visible' in d) || d.Visible);

            // Force reload
            this.unique_id = utils.generateUUID();
        },
        onGridReady(params) {
            this.gridApi = params.api;
            this.columnApi = params.columnApi;

            if (this.controlData.EnableTimerWatch)
                this.watch_date_value$ = this.$watch(function () {
                    return utils.helpers.chf.date_value.value;
                }, function (newvalue) {
                    this.gridApi.refreshCells();
                });

            this.finishRenderHandler(this);
        },
        onFirstDataRendered(params) {
            const statestr = this.System.LocalStorage(this.storageKey + '-state');
            const stateObj = statestr ? JSON.parse(statestr) : null;

            if (stateObj) {
                params.api.applyColumnState({state: stateObj.columnState, applyOrder: true});
                this.gridApi.setColumnGroupState(stateObj.columnGroupState);
            }
            else if (this.controlData.SizeColumnnsToContent)
                this.SizeColumnsToFit();
            else if (this.controlData.ColumnDefs)
                for (let i = 0; i < this.controlData.ColumnDefs.length; i++)
                    if (this.controlData.ColumnDefs[i].Width == 'SizeToContent' && this.controlData.ColumnDefs[i].Field)
                        this.gridApi.autoSizeColumn(this.controlData.ColumnDefs[i].Field);

            const sortstr = this.System.LocalStorage(this.storageKey + '-sort');
            const sortObj = sortstr ? JSON.parse(sortstr) : null;
            const sortDef = this.columnDefs?.find(c => (this.controlData.DefaultSort && c.Field == this.controlData.DefaultSort) || (!this.controlData.DefaultSort && c.Sortable));
            
            if (sortObj) {
                this.gridApi.applyColumnState(sortObj);
            } else if (sortDef) {
                const sort = [{ colId: sortDef.Field, sort: 'desc' }];
                this.gridApi.applyColumnState(sort);
            }
                
            const column_filters = this.System.LocalStorage(this.storageKey + '-column_filters');
            const filterObj = column_filters ? JSON.parse(column_filters) : null;

            if (filterObj)
                this.gridApi.setFilterModel(filterObj);

            setTimeout(() => this.resolveAllAdditionalRowData(), 1000);
            //Vue.nextTick(() => this.resolveAllAdditionalRowData());
        },
        SizeColumnsToFit() {
            this.gridApi.autoSizeAllColumns();
        },
        rowSelectionChanged(e) {
            const rows = this.gridApi.getSelectedNodes();

            utils.log(`rowSelectionChanged - ${rows.length} row(s) selected`);

            // rows[0].data
            // this.selectedRowKeys = rows.map(d => this.controlData.Keys.map(k => ({ [k]: d.data[k] })));

            if (this.controlData.KeyFields) {
                const selected_rows = {};
                for (let i = 0; i < rows.length; i++) {
                    const row = rows[i].data;
                    const key = this.controlData.KeyFields.map(k => `_${row[k]}`).join('');
                    selected_rows[key] = row;
                }
                this.selected_rows = selected_rows;
            }
            this.selectedRowsData = rows.map(d => d.data);
        },

        onSaveGridSortState(e) {
            if (!this.gridApi) return;

            let sortModel = this.gridApi.getColumnState().map(a => ({ colId: a.colId, sort: a.sort }));

            if (this.useLocalStorage) {
                this.System.LocalStorage(this.storageKey + '-sort', JSON.stringify(sortModel));
            }
        },
        onSaveGridColumnState: debounce(function (e) {
            if (!this.gridApi || (!e.column && e.type == 'columnMoved')) return;

            let columnState = this.gridApi.getColumnState();
            let columnGroupState = this.gridApi.getColumnGroupState();

            const state = {
                columnState: columnState,
                columnGroupState: columnGroupState,
            };

            if (this.useLocalStorage) {
                this.System.LocalStorage(this.storageKey + '-state', JSON.stringify(state));
            }
        }, 250),
    }
};

export default baseMixin;