<template>
    <div style="display: flex; flex-direction: row; flex-wrap: wrap;" class="mt-1 mb-2">
        <div v-if="items.length == 0" class="text-caption ml-3 text--secondary">
            No items visible - check your filter settings
        </div>
        <div v-else v-for="(metric,j) in items" :key="j" style="width: 320px; max-width: 320px; display: flex; flex-direction: row;" class="mr-4">
            <v-menu offset-y
                    v-if="(metric.MetricAggs.Type == 'function' || metric.MetricAggs.Type == 'filter' || metric.MetricAggs.Type == 'script' || metric.MetricAggs.Type == 'filter_script') && metric.MetricAggs.FunctionList.length > 1"
                    :disabled="isDisabledSource(metric)" 
                    :close-on-content-click="false">

                <template v-slot:activator="{ on, attrs }">
                    <v-btn text small v-bind="attrs" v-on="on" class="text-normal subtitle-1" @click="metricMenu = []" :disabled="isDisabledSource(metric)">
                        <v-icon class="mr-1" style="color: var(--v-secondaryText-base)" :color="getMetricIconColor(metric)">{{ getMetricItemIcon(metric) }}</v-icon>
                        <span class="text-truncate" style="maxWidth:210px;">{{ metric.Title }}</span>
                        <v-icon color="secondaryText">mdi-menu-down</v-icon>
                    </v-btn>
                </template>

                <v-list dense>
                    <v-list-item-group v-model="metricMenu" color="primary">
                        <template v-for="func in getSortedFunctions(metric)">
                            <v-list-item :key="func" @click="isDisabledMetric(metric, func) ? removeMetric(metric, func) : addMetric(metric, func)" _disabled="isDisabledMetric(metric, func)">
                                
                                <v-list-item-icon><v-icon color="secondaryText">{{ isDisabledMetric(metric, func) ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}</v-icon></v-list-item-icon>
                                <v-list-item-title>{{ getFunctionDisplayName(metric, func) }}</v-list-item-title>

                            </v-list-item>
                        </template>
                    </v-list-item-group>
                </v-list>

            </v-menu>
            <v-btn v-if="(metric.MetricAggs.Type == 'function' || metric.MetricAggs.Type == 'filter' || metric.MetricAggs.Type == 'script' || metric.MetricAggs.Type == 'filter_script') && metric.MetricAggs.FunctionList.length == 1"
                   text small class="text-normal subtitle-1" @click="(evt) => isDisabledMetric(metric, metric.MetricAggs.FunctionList[0]) ? removeMetric(metric, metric.MetricAggs.FunctionList[0]) : addMetric(metric, metric.MetricAggs.FunctionList[0])"
                   :disabled="isDisabledSource(metric)">
                <v-icon v-if="isMetricInUse(metric)" class="mr-1" color="primary">mdi-checkbox-marked</v-icon>
                <v-icon v-else class="mr-1" color="secondaryText">mdi-checkbox-blank-outline</v-icon>
                <span class="text-truncate" :style="{ maxWidth: '230px' }">{{ getMetricDisplayName(metric, metric.MetricAggs.FunctionList[0]) }}</span>
            </v-btn>
            <v-btn v-if="metric.MetricAggs.Type == 'bucket_script'"
                   text small class="text-normal subtitle-1" @click="(evt) => isDisabledMetric(metric, 'bucket') ? removeMetric(metric, 'bucket') : addMetric(metric, 'bucket')"
                   :disabled="isDisabledSource(metric)">
                <v-icon v-if="isMetricInUse(metric)" class="mr-1" color="primary">mdi-checkbox-marked</v-icon>
                <v-icon v-else class="mr-1" color="secondaryText">mdi-checkbox-blank-outline</v-icon>
                <span class="text-truncate" :style="{ maxWidth: '230px' }">{{ metric.Title }}</span>
            </v-btn>

            <v-spacer></v-spacer>

            <v-tooltip bottom open-delay="350">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon small v-bind="attrs" v-on="on" :class="{ 'ma-0': true }" style="color:lightGrey" @click="showHelp(metric)">
                        <v-icon small>mdi-information-slab-circle</v-icon>
                    </v-btn>
                </template>
                <span style="font-weight: bold;">{{ metric.Category }} - {{ metric.Title }}</span><br />
                <span>{{ getMetricHelp(metric) }}</span><br />
                <span style="font-size: smaller; font-style: italic;">Source(s): {{ metric.Sources.join(', ') }}</span>
                <br v-if="metric.Dependencies && metric.Dependencies.Modules.length" />
                <span style="font-size: smaller; font-style: italic;" v-if="metric.Dependencies && metric.Dependencies.Modules.length">Module(s): {{ metric.Dependencies.Modules.join(', ') }}</span>
            </v-tooltip>
        </div>
    </div>
</template>

<script>
    import common from './common.js';

    export default {
        data: function () {
            return {
                metricMenu: [],
            }
        },
        props: {
            items: null,
            columnLookup: null,
            isMetricInUse: null,
            getMetricItemIcon: null,
            showHelp: null,
            isDisabledMetric: null,
            isDisabledSource: null,
            addMetric: null,
            removeMetric: null,
            getMetricHelp: null,
            metric_types: null,
        },
        created() {
        },
        methods: {
            getFunctionDisplayName(metric, func) {
                const col = this.columnLookup[metric.ColId];
                if (col)
                    return common.formatMetric({ type: col.SourceType, aggFunc: func });
                else
                    return func;
            },
            getMetricDisplayName(metric, func) {
                const col = this.columnLookup[metric.ColId];
                if (col)
                    return `${metric.Title} (${common.formatMetric({ headerName: col.Title, type: col.SourceType, aggFunc: func })})`;
                    //return common.formatHeader({ headerName: col.Title, type: col.SourceType, aggFunc: func });
                else
                    return metric.Title;
            },
            getMetricIconColor(item) {
                switch (this.getMetricItemIcon(item)) {
                    case 'mdi-checkbox-marked': return 'primary';
                    case 'mdi-checkbox-intermediate': return 'primary';
                    case 'mdi-checkbox-blank-outline':
                    default:
                        return 'currentColor';
                }
            },
            getSortedFunctions(metric) {
                return metric.MetricAggs.FunctionList.toSorted((a, b) => common.byField(this.getFunctionDisplayName(metric, a), this.getFunctionDisplayName(metric, b)));
            },
        }
    }
</script>

<style scoped>
    .hidden-element {
        visibility: hidden;
    }

    .columns-two {
        column-count: 2;
    }

    .columns-three {
        column-count: 3;
    }

    .verydense {
        max-height: 24px;
        min-height: 24px;
    }

    .mediumdense {
        max-height: 28px;
        min-height: 28px;
    }

    p {
        font-size: 2em;
        text-align: center;
    }

    .metric_normal {
    }

    .metric_inuse {
        color: cornflowerblue;
    }

    .metric_usedup {
        color: silver;
    }
</style>